import dataManager from '@/plugins/dataManager'

export default {
  data () {
    return {
      user: {
        username: '',
        password: '',
        deviceId: ''
      },
      loading: false,
      showPassword: false,
      formRules: {
        required: [
          v => !!v || 'This field is required'
        ]
      }
    }
  },
  methods: {
    login () {
      const scope = this
      scope.loading = true
      const data = {
        username: scope.user.username,
        password: scope.user.password,
        deviceId: scope.$store.getters.getBrowserId
      }
      dataManager.post('checkIn', 'auth/login', data)
        .then((data) => {
          scope.$store.commit('setIsLoggedIn', true)
          scope.$store.commit('setSessionUser', data.user)
          scope.$store.commit('setPermissions', data.permissions)
          scope.$store.commit('setToken', data.token)
          if (!scope.$route.query.redirect) {
            scope.$router.push({ name: 'CheckIn' })
          } else {
            scope.$router.push(scope.$route.query.redirect)
          }
        })
        .catch((err) => {
          scope.loading = false
          scope.$snotify.error(err.message)
          scope.$store.commit('setIsLoggedIn', false)
        })
    }
  }
}
